import { DEVICES } from '@constants/global'
import { COLORS } from '@styles/colors'
import { FONTS, textXS } from '@styles/fonts'
import styled, { css } from 'styled-components'

export const ListingTag = styled.span`
  position: absolute;
  bottom: 10px;
  right: 10px;
  color: ${COLORS.nature.base};
  background-color: ${COLORS.primary};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  font-family: ${FONTS.bold};
  font-size: 16px;
  letter-spacing: 1px;
  border-radius: 50%;
  text-transform: uppercase;
  z-index: 1;

  ${({ big }) =>
    big &&
    css`
      width: 140px;
      height: 140px;
      font-size: 28px;
      letter-spacing: 2.3px;
      right: 24px;
      bottom: 30px;
      z-index: 9;

      @media ${DEVICES.mobile} {
        width: 72px;
        height: 72px;
        right: 10px;
        bottom: 10px;
        font-size: 14px;
        letter-spacing: 1.2px;
      }
    `}
`

export const ListingTags = styled.div`
  position: absolute;
  display: inline-flex;
  top: 13px;
  left: 10px;
  height: auto !important;
`

export const ListingTagSmall = styled.div`
  ${textXS};
  padding: 2px 10px;
  text-transform: uppercase;
  font-family: ${FONTS.bold};
  color: ${COLORS.secondary};
  background: ${COLORS.nature[95]};
  border-radius: 20px;
  margin-right: 8px;
`
