import { DEVICES } from '@constants/global'
import { COLORS } from '@styles/colors'
import { FONTS, textBase, textSM } from '@styles/fonts'
import styled, { css } from 'styled-components'

export const ListingCardImage = styled.div`
  position: relative;
  overflow: hidden;
  border-radius: 0 0 40px 0;
  font-size: 0;
  line-height: 0;
  margin-bottom: 18px;

  img {
    transition: transform 0.25s linear;
    width: 100%;
  }
`

export const ListingCardContent = styled.div`
  display: flex;

  > div:first-child {
    flex: 1;
  }
`

export const ListingCardAddress = styled.h3`
  font-size: 16px;
  line-height: 22px;
  text-transform: uppercase;
  font-family: ${FONTS.regular};
  letter-spacing: 1.4px;
  margin-bottom: 12px;

  a {
    color: ${COLORS.secondary};
  }
`

export const ListingCardFeatures = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`

export const ListingCardFeature = styled.div`
  display: flex;
  align-items: center;
  color: ${COLORS.secondary};
  margin-right: 22px;
  transition: all 0.25s ease-in;
`

export const ListingCardFeatureLabel = styled.span`
  margin-right: 8px;
  font-family: ${FONTS.medium};
`

export const ListingCardFooter = styled.div``

export const ListingCardPrice = styled.span`
  color: ${COLORS.navi};
  font-family: ${FONTS.medium};
  transition: all 0.25s ease-in;
`

export const ListingCardAgentOffice = styled.div`
  margin-left: 24px;
  text-align: center;
  width: 110px;

  img {
    width: 80px;
    height: 80px;
    border-radius: 100%;
    margin-bottom: 12px;
    object-fit: cover;
  }
`

export const ListingCardAgentOfficeName = styled.h5`
  ${textSM};
  font-family: ${FONTS.medium};
  transition: all 0.25s ease-in;
  color: ${COLORS.secondary};
  height: 40px;
  letter-spacing: 1px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`

export const ListingCard = styled.div`
  position: relative;

  &:hover {
    ${ListingCardImage} img {
      transform: scale(1.1);
    }
  }

  > a {
    position: absolute;
    width: 100%;
    height: 100%;
    display: block;
    z-index: 1;
  }

  ${({ results }) =>
    results &&
    css`
      margin-bottom: 40px;
      flex: 1;
      background-color: ${COLORS.nature[95]};
      transition: all 0.25s ease-in;

      &:hover {
        background-color: ${COLORS.navi};
        color: ${COLORS.white};
        cursor: pointer;

        ${ListingCardPrice},
        ${ListingCardAddress} a,
        ${ListingCardAddress} span,
        ${ListingCardFeature},
        ${ListingCardAgentOfficeName} {
          color: ${COLORS.white};
        }
      }

      ${ListingCardImage} {
        border-radius: 0 0 52px 0;
      }

      ${ListingCardContent} {
        padding: 0 18px 24px;
      }

      @media ${DEVICES.mobile} {
        ${ListingCardAgentOffice} {
          display: none;
        }
      }
    `}

  ${({ mapView }) =>
    mapView &&
    css`
      display: flex;

      ${ListingCardImage} {
        width: 270px;
        border-radius: 0;
        margin-bottom: 0;
        flex: 1;

        div {
          height: 100%;
        }

        img {
          height: 100%;
          object-fit: cover;
        }
      }

      ${ListingCardAddress} {
        margin-bottom: 8px;
      }

      ${ListingCardFeatures} {
        ${textBase};
        margin-bottom: 12px;
      }

      ${ListingCardFooter} {
        ${textSM};
      }

      ${ListingCardPrice} {
        ${textBase};
        display: block;
        margin-bottom: 24px;
      }

      ${ListingCardContent} {
        padding: 24px;
        justify-content: space-between;
      }
    `}
`
