import IconSearch from '@icons/Search'
import useWindowSize from '@hooks/useWindowSize'
import { getAutocompleteData } from '@lib/api-services/autocompleteService'
import { propertyDetailsUrl, titleCase } from '@lib/helpers'
import { useEffect, useId, useState } from 'react'
import { Router } from '@routes'
import IconClose from '@icons/Close'
import AsyncSelect from 'react-select/async'
import { useFormikContext } from 'formik'
import IconPlus from '@icons/Plus'

import * as S from './Autocompete.styled'

const OPTION_TYPE = {
  PROPERTY: 'properties',
  REGION: 'regions',
  SUBURB: 'suburbs',
  POSTCODE: 'postcodes',
}

const getOptionFromQueryString = (value, type) => {
  return value.split(',').map((it) => ({
    label: titleCase(it),
    value: JSON.stringify({ value: it, type }),
    type,
  }))
}

const Autocompete = ({
  size,
  onChange,
  className,
  iconSearchClick,
  iconSearchPosition = 'left',
  toggleFilters,
  showFilters = false,
  positionMenu = 'bottom',
  listingType = '',
  status,
}) => {
  const { values } = useFormikContext()
  const [searchText, setSearchText] = useState('')
  const [placeholder, setPlaceholder] = useState(
    'Search by suburb, postcode or region'
  )
  const [value, setValue] = useState(null)
  const windowSize = useWindowSize()

  const loadOptions = async (q, callback) => {
    setSearchText(q)
    let listing_type = ''
    if (values.listing_type) {
      listing_type = values.listing_type
    }
    let params = {
      listing_type: listing_type,
    }
    if (q?.length < 3) return
    if (status === 'sold' || listingType === 'sold') {
      params = {
        status: 'sold',
      }
    }
    const options = await getAutocompleteData({ q, ...params })
    callback(options)
  }

  const clearOptions = () => {
    setValue([])
    if (onChange) {
      onChange({
        address_region: '',
        address_suburb: '',
        address_postcode: '',
      })
    }
  }

  const onFieldChange = async (options = []) => {
    let params = {
      address_region: '',
      address_suburb: '',
      address_postcode: '',
    }
    const selectedOptions = options.map((option) => JSON.parse(option.value))
    const selectedProperty = selectedOptions.find(
      (it) => it.type === OPTION_TYPE.PROPERTY
    )

    const selectedRegions =
      selectedOptions
        .filter((it) => it.type === OPTION_TYPE.REGION)
        ?.map((it) => it.value)
        ?.toString() ?? ''
    const selectedSuburbs =
      selectedOptions
        .filter((it) => it.type === OPTION_TYPE.SUBURB)
        ?.map((it) => it.value)
        ?.toString() ?? ''

    const selectedPostcodes =
      selectedOptions
        .filter((it) => it.type === OPTION_TYPE.POSTCODE)
        ?.map((it) => it.value)
        ?.toString() ?? ''

    if (selectedProperty) {
      await Router.pushRoute(
        propertyDetailsUrl({
          ...selectedProperty,
          listing_type: listingType,
          status,
        })
      )
    } else {
      if (selectedRegions) {
        params.address_region = selectedRegions
      }
      if (selectedSuburbs) {
        params.address_suburb = selectedSuburbs
      }
      if (selectedPostcodes) {
        params.address_postcode = selectedPostcodes
      }

      setValue(options)

      if (onChange) {
        onChange(params)
      }
    }
  }

  useEffect(() => {
    let options = []
    if (values.address_region) {
      options = options.concat(
        getOptionFromQueryString(values.address_region, OPTION_TYPE.REGION)
      )
    }
    if (values.address_suburb) {
      options = options.concat(
        getOptionFromQueryString(values.address_suburb, OPTION_TYPE.SUBURB)
      )
    }
    if (values.address_postcode) {
      options = options.concat(
        getOptionFromQueryString(values.address_postcode, OPTION_TYPE.POSTCODE)
      )
    }

    setValue([...options])
  }, [values.address_region, values.address_suburb, values.address_postcode])

  useEffect(() => {
    const newPlaceholder =
      windowSize?.width > 767
        ? 'Search by suburb, postcode or region'
        : 'Suburb, postcode or region'
    if (newPlaceholder !== placeholder) {
      setPlaceholder(newPlaceholder)
    }
  }, [windowSize?.width, placeholder])

  return (
    <S.SearchInput
      size={size}
      className={className}
      showFilters={showFilters}
      iconSearchPosition={iconSearchPosition}
    >
      {iconSearchClick ? (
        <IconSearch onClick={iconSearchClick} />
      ) : (
        <IconSearch />
      )}
      {showFilters && (
        <S.SelectedFilters onClick={toggleFilters} className={'btn-filters'}>
          Filter
          <IconPlus />
        </S.SelectedFilters>
      )}
      <AsyncSelect
        instanceId={useId()}
        loadOptions={loadOptions}
        onChange={onFieldChange}
        classNamePrefix='bp-autocomplete'
        className='bp-autocomplete'
        value={value}
        isMulti
        cacheOptions
        components={{
          IndicatorSeparator: () => null,
          DropdownIndicator: () => null,
          ClearIndicator: () => (
            <IconClose
              width={16}
              onClick={clearOptions}
              onTouchEnd={clearOptions}
            />
          ),
        }}
        noOptionsMessage={() =>
          searchText?.length < 3 ? null : 'No results found'
        }
        onMenuClose={() => setSearchText('')}
        placeholder={placeholder}
        menuPosition='fixed'
        blurInputOnSelect={false}
        menuPlacement={positionMenu}
      />
    </S.SearchInput>
  )
}

export default Autocompete
