import React, { useEffect, useRef, useState } from 'react'
import FavouriteHeart from '@global/FavouriteHeart'
import { Link } from '@routes'
import Image from '@global/Image'
import * as S from './ArticleCard.styled'
import useWindowSize from '@hooks/useWindowSize'

const ArticleCard = ({ article, hideFavourite }) => {
  article.preamble = article.preamble || ''
  const [bodyHeight, setBodyHeight] = useState(null)
  const windowSize = useWindowSize()
  const bodyRef = useRef(null)

  useEffect(() => {
    setBodyHeight(bodyRef.current?.scrollHeight)
  }, [windowSize.width, article?.preamble])

  return (
    <S.Card>
      <S.CardImage>
        <Link route={article?.link?.url} passHref>
          <Image src={article.imgUrl} alt={article.title} />
        </Link>
        {article.id && !hideFavourite && (
          <FavouriteHeart type='post' id={article.id} />
        )}
      </S.CardImage>
      <S.CardBody height={bodyHeight} ref={bodyRef}>
        <S.CardTitleWrap>
          <S.CardTitle>{article.title}</S.CardTitle>
        </S.CardTitleWrap>
        <S.CardContent>
          {article?.preamble && (
            <S.Description>{article?.preamble}</S.Description>
          )}
        </S.CardContent>
      </S.CardBody>
      <Link route={article?.link?.url} passHref />
    </S.Card>
  )
}

export default ArticleCard
