import App from 'next/app'
import 'normalize.css'
import '@styles/fonts.css'
import '@styles/global.css'
import '/static/nprogress.css'
import NProgress from 'nprogress'
import 'react-tooltip/dist/react-tooltip.css'
import {
  getMainMenus,
  getFlatMenus,
  getSiteSettings,
} from '@lib/api-services/cmsPageService'
import { ApiKeys } from '@lib/variables'
import { Router } from '@routes'
import { DefaultSeo } from 'next-seo'
import SEO from '../next-seo.config'
import { flatMenusToNav, mainMenusToNav } from '@lib/api'
import { OfficeSiteProvider } from '@context/OfficeSiteContext'
import { UserContextProvider } from '@context/UserContext'
import { EnquiryFormProvider } from '@context/EnquiryFormContext'
import { SiteSettingsContextProvider } from '@context/SiteSettingsContext'
import { FormModalProvider } from '@context/FormModalContext'
import { initGA, logPageView } from '@lib/analyticsService'
import Head from 'next/head'
import FavouriteModal from '@global/FavouriteModal'
import { GoogleOAuthProvider } from '@react-oauth/google'
import * as Sentry from '@sentry/browser'
import Layout from '@global/Layout'
import { Tooltip } from 'react-tooltip'
import dynamic from 'next/dynamic'

const ToastNotification = dynamic(() => import('@global/ToastNotification'), {
  ssr: false,
})

Sentry.init({
  dsn: ApiKeys.SENTRY_DSN,
})
initGA()
class BarryPlantApp extends App {
  static async getInitialProps({ Component, ctx }) {
    let pageProps = {}

    if (Component.getInitialProps) {
      pageProps = await Component.getInitialProps(ctx)
      ctx.pageProps = pageProps
    }

    let menuData
    let settings

    if (typeof window === 'undefined') {
      // Fetch menu data from the server
      const [flatMenusData, mainMenusData, siteSettings] = await Promise.all([
        getFlatMenus(),
        getMainMenus(),
        getSiteSettings(),
      ])

      // Convert menu data to desired format
      const [footerMenus, footerMainMenus, accountMenus, megaNavMenus] =
        flatMenusToNav(flatMenusData)
      const mainMenus = mainMenusToNav(mainMenusData)

      menuData = {
        footerMenus,
        footerMainMenus,
        mainMenus,
        accountMenus,
        megaNavMenus,
      }

      settings = siteSettings
    }

    return {
      pageProps,
      siteSettings: settings,
      menuData,
    }
  }

  timer = null
  componentDidCatch(error, errorInfo) {
    Sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key])
      })

      Sentry.captureException(error)
    })

    super.componentDidCatch(error, errorInfo)
  }
  routeChangeStart = () => {
    clearTimeout(this.timer)
    this.timer = setTimeout(NProgress.start, 300)
  }

  routeChangeEnd = () => {
    clearTimeout(this.timer)
    NProgress.done()
  }

  componentDidMount() {
    // google analytics
    if (!window.GA_INITIALIZED) {
      initGA()
      window.GA_INITIALIZED = true
    }
    logPageView()

    // page load progress
    Router.events.on('routeChangeStart', this.routeChangeStart)
    Router.events.on('routeChangeError', this.routeChangeEnd)
    Router.events.on('routeChangeComplete', () => {
      this.routeChangeEnd()
      logPageView()
    })
  }

  componentWillUnmount() {
    // page load progress
    Router.events.off('routeChangeStart', this.routeChangeStart)
    Router.events.off('routeChangeComplete', this.routeChangeEnd)
    Router.events.off('routeChangeError', this.routeChangeEnd)
  }

  render() {
    const { pageProps, Component, menuData, siteSettings, ...ctx } = this.props
    let menu = { ...menuData }
    let settings = { ...siteSettings }

    if (typeof window !== 'undefined') {
      if (menuData) {
        localStorage.setItem('menuData', JSON.stringify(menuData))
        localStorage.setItem('siteSettings', JSON.stringify(siteSettings))
      } else {
        menu = JSON.parse(localStorage.getItem('menuData') ?? '{}')
        settings = JSON.parse(localStorage.getItem('siteSettings') ?? '{}')
      }
    }

    const isHomePage = pageProps.wagtailPageType === 'HomePage'

    return (
      <>
        <Head>
          <meta
            name='viewport'
            content='initial-scale=1.0, width=device-width, user-scalable=yes'
          />
          <meta property='fb:app_id' content={ApiKeys.FACEBOOK_APP_ID} />
        </Head>
        <GoogleOAuthProvider clientId={ApiKeys.GOOGLE_OAUTH_ID}>
          <UserContextProvider ctx={ctx}>
            <SiteSettingsContextProvider siteSettings={{ ...settings }}>
              <OfficeSiteProvider>
                <FormModalProvider>
                  <EnquiryFormProvider>
                    <DefaultSeo {...SEO} />
                    <Layout isHomePage={isHomePage} menuData={{ ...menu }}>
                      <Component {...pageProps} />
                    </Layout>
                    <ToastNotification />
                    <FavouriteModal />
                    <Tooltip id='bp-tooltip' />
                  </EnquiryFormProvider>
                </FormModalProvider>
              </OfficeSiteProvider>
            </SiteSettingsContextProvider>
          </UserContextProvider>
        </GoogleOAuthProvider>
      </>
    )
  }
}
export default BarryPlantApp
